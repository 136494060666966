<template>
  <div class="bg-white default-rounded q-pa-md">
    <q-parallax>
      <template v-slot:content="scope">
        <div
          class="absolute column items-center"
          :style="{
            opacity: 0.45 + (1 - scope.percentScrolled) * 0.55,
            top: scope.percentScrolled * 60 + '%',
            left: 0,
            right: 0,
          }"
        >
          <div
            class="text-center text-weight-thin"
            :class="{
              'text-h4': $q.platform.is.desktop,
              'text-h5': $q.platform.is.mobile,
            }"
          >
            {{ $t("notifications.alert_not_found") }}
          </div>
          <div class="text-center text-weight-medium text-h6 q-pa-md">
            {{ $t("notifications.keep_trying") }}
          </div>
        </div>
      </template>
    </q-parallax>
  </div>
</template>

<style scoped>
.padding-top {
  padding-top: 2em;
}
</style>
