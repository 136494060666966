<template>
  <div class="body-content">
    <div class="row">
      <div class="col-12 col-md-2 image-align q-mb-md">
        <q-img width="10em" src="/awards-icons/award_bonification.png" />
      </div>
      <div class="col-12 col-md-7">
        <div class="q-mb-sm text-uppercase text-grey-8">
          {{ reward.title }}
        </div>

        <p class="q-pr-sm text-justify" style="font-size: 9pt;">
          {{ $t("my_points.awards.bonification_award") }}
        </p>
      </div>
      <div class="col-12 col-md-3 text-data">
        <p>
          <span class="text-weight-bold">
            {{ $t("my_points.awards.infos.get_date") }}
          </span>
          {{ formatDate(reward.reward_created_at) }}
        </p>
        <p v-if="reward.money_due_date != null">
          <span class="text-weight-bold">
            {{ $t("my_points.awards.infos.prev_payment_date") }} </span
          >{{ formatPrevDate(reward.money_due_date) }}
        </p>
        <p v-if="reward.date_money_liquidated_at != null">
          <span class="text-weight-bold">
            {{ $t("my_points.awards.infos.free_date") }} </span
          >{{ formatDate(reward.date_money_liquidated_at) }}
        </p>
        <p v-if="reward.partner_email != null">
          <span class="text-weight-bold">
            {{ $t("my_points.awards.infos.send_email") }} </span
          >{{ reward.partner_email }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { toMoneyLabel } from "@/shared/helpers/numbersManipulation";
import moment from "moment";
import { ref } from "vue";

export default {
  name: "AwardBonification",

  props: {
    reward: {
      type: Object,
      required: true,
    },
  },

  setup() {
    const formatDate = (date) => moment(date).format("DD/MM/YYYY  HH:mm:ss");
    const formatPrevDate = (date) => moment(date).format("DD/MM/YYYY");

    return {
      formatDate,
      formatPrevDate,
      toMoneyLabel,
      ref,
    };
  },
};
</script>

<style scoped lang="scss">
.body-content {
  background-color: white;
  padding: 20px;
}
p {
  font-size: 12pt;
}
.image-align {
  text-align: center;
}
.show-more {
  color: blue;
  font-weight: bolder;
}
.text-data {
  text-align: right;
  p {
    font-size: 9pt;
  }
}
@media only screen and (max-width: 1090px) {
  .image-align {
    text-align: center;
  }
  .text-data {
    text-align: left;
  }
}
</style>
