<template>
  <div class="reward q-mt-md" @click="show_products = !show_products">
    <div
      class="
        reward-item
        no-wrap
        default-rounded default-shadow
        row
        justify-between
      "
    >
      <div
        class="
          row
          flex-center
          justify-between
          reward-item-info
          q-ma-lg
          width-100
        "
      >
        <div class="order-details-item">
          <div class="row">
            <q-img width="4.2em" :src="reward.option.image_full_path" />
            <div class="text-dark text-uppercase text-left q-mt-md">
              <span
                style="font-size: 14pt; font-weight: bolder; margin: 15px"
                :class="reward.option.id == 5 ? 'q-mt-lg' : ''"
                >{{ reward.option.title }}</span
              >
            </div>
          </div>

          <div class="p-status" style="font-size: 12pt; font-weight: bolder">
            {{ "Status:" }} {{ reward.status_label }}
          </div>
        </div>
        <div class="order-details-item q-mt-sm">
          <div class="text-weight-medium text-grey-7">
            <span style="font-size: 12pt; font-weight: bolder">
              <strong>{{ "Créditos Utilizados:" }}</strong>
              {{ reward.amount }}</span
            >
          </div>
          <div class="text-weight-medium text-grey-7 q-mt-sm">
            <span style="font-size: 12pt; font-weight: bolder">
              <strong>{{ "Valor Final:" }}</strong>
              {{ toMoneyLabel(reward.finantial_value) }}</span
            >
          </div>
        </div>
      </div>
      <div class="control row" v-if="showMoreData(reward.option.id)">
        <button class="button-chevron">
          <i
            class="fa"
            :class="{
              'fa-chevron-down': !show_products,
              'fa-chevron-up': show_products,
            }"
          >
          </i>
        </button>
      </div>
    </div>
  </div>
  <div v-if="show_products">
    <all-awards :reward="reward" />
  </div>
</template>

<script>
import { toMoneyLabel } from "@/shared/helpers/numbersManipulation";
import allAwards from "./all-awards";
import { ref } from "vue";
import { get } from "lodash";
import moment from "moment";

export default {
  name: "all-awards-items",

  components: {
    allAwards,
  },

  props: {
    reward: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const show_products = ref(false),
      created_date = moment(get(props.reward, "created_at")).format("LL");

    const showMoreData = (option_id) => {
      const dontShow = [5];

      return !dontShow.includes(option_id);
    };

    return {
      show_products,
      created_date,
      toMoneyLabel: toMoneyLabel,
      showMoreData,
    };
  },
};
</script>

<style scoped lang="scss">
.reward {
  cursor: pointer;
}
.reward {
  &.AAP,
  &.CAN {
    opacity: 0.6;
  }
  &.CAN {
    .p-title {
      text-decoration: line-through;
    }
    .p-status {
      color: var(--q-negative) !important;
    }
  }
  .reward-item {
    background-color: #f3f3f3;
    .reward-badge {
      max-width: 180px;
      width: 100%;
      background: #eeeeee;
      border-radius: 10px;
      font-weight: 400;
      min-width: 134px;
      text-align: center;
      color: aliceblue;
    }
  }
  .reward-item-info {
    .p-title {
      color: #2f3235;
    }
    .p-status {
      font-weight: 500;
      font-size: 13px;
      margin-top: 10px;
      color: #666666;
    }
  }
  .control {
    .button-chevron {
      background: transparent;
      border: none;
      cursor: pointer;
      outline: none;
      padding: 0 25px;
    }
  }
  .details {
    background: #eeeeee;
    padding: 20px;
    margin-top: -20px;
    .content {
      margin-top: 3px;
      border-top: 1px solid #e4e4e4;
      position: relative;
      .d-badge {
        position: absolute;
        left: -140px;
        top: 10px;
        font-weight: 400;
      }
    }
  }
}
.mobile {
  .reward {
    .reward-item {
      .reward-badge {
        font-size: 10px;
        min-width: 80px;
      }
    }
    .order-details-item {
      font-size: 12px;
    }
    .details {
      .content {
        margin-left: 0;
      }
    }
  }
}
</style>
