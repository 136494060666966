<template>
  <q-expansion-item expand-separator label="MAIS INFORMAÇÕES" class="label">
    <q-card>
      <q-card-section style="padding: -20px">
        <div v-if="verifyMoreInfo()">
          <template v-for="(item, i) in moreinfo" :key="i">
            <p class="text-justify text-dark" v-html="item.chave" />
            <p class="text-justify text-dark" v-html="item.valor" />
          </template>
        </div>
        <div v-else>
          <p class="text-justify text-dark" v-html="moreinfo.chave" />
          <p class="text-justify text-dark" v-html="moreinfo.valor" />
        </div>
      </q-card-section>
    </q-card>
  </q-expansion-item>
</template>

<script>
export default {
  name: "award-more-info",

  props: {
    moreinfo: {
      type: Array,
      required: true,
    },
  },

  setup(props) {
    const verifyMoreInfo = () => {
      return props.moreinfo.length;
    };

    return { verifyMoreInfo };
  },
};
</script>

<style scoped>
.label {
  color: blue;
  font-size: 12pt;
  margin-left: -10px;
  margin: -50px;
  padding: 40px;
}
</style>
