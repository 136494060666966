<template>
  <div class="awards_contents">
    <award-bonification
      class="bonification-ajust"
      v-if="reward.type == 2"
      :reward="reward"
    />
    <award-gift v-if="reward.type == 3" :reward="reward" />
    <award-voucher
      class="voucher-ajust"
      v-if="reward.type == 1"
      :reward="reward"
    />
    <award-mastercard
      class="voucher-ajust"
      v-if="reward.type == 6"
      :reward="reward"
    />
  </div>
</template>

<script>
import awardBonification from "./award-bonification.vue";
import awardVoucher from "./award-voucher.vue";
import awardGift from "./award-gift.vue";
import awardMastercard from "./award-mastercard.vue";

export default {
  name: "AllAwards",

  components: {
    awardGift,
    awardVoucher,
    awardBonification,
    awardMastercard,
  },

  props: {
    reward: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.awards_contents {
  .bonification-ajust {
    margin-bottom: 0px;
  }
  .voucher-ajust {
    margin-bottom: -5px;
  }
}

@media only screen and (max-width: 1090px) {
  .awards_contents {
    .bonification-ajust {
      margin-bottom: 0px;
    }
    .voucher-ajust {
      margin-bottom: 0px;
    }
  }
}
</style>
