<template>
  <div class="body-content">
    <div class="row">
      <div class="col-12 col-md-2 image-align q-mb-md">
        <q-img width="10em" :src="getGiftImage(reward.imagens)" />
      </div>
      <div class="col-12 col-md-7">
        <div class="q-mb-sm text-uppercase text-grey-8">
          {{ reward.produto_nome }}
        </div>
        <p
          class="q-pr-sm text-justify"
          v-if="!showMore"
          style="font-size: 9pt;"
        >
          {{ formatDescription(reward.descricao) }}..
          <a href="#" class="show-more" @click.prevent="showMore = !showMore">{{
            $t("global.show_more")
          }}</a>
        </p>
        <p class="q-pr-sm text-justify" v-else style="font-size: 9pt;">
          {{ reward.descricao }}..
          <a href="#" class="show-more" @click.prevent="showMore = !showMore">{{
            $t("global.show_less")
          }}</a>
        </p>
        <award-more-info
          style="font-size: 9pt;"
          :moreinfo="JSON.parse(reward.informacoes_adicionais).Informacao"
        />
      </div>

      <div class="col-12 col-md-3 text-data">
        <p v-if="reward.partner_email != null">
          <span class="text-weight-bold">
            {{ $t("my_points.awards.infos.send_email") }} </span
          >{{ reward.partner_email }}
        </p>
        <p>
          <span class="text-weight-bold">
            {{ $t("my_points.awards.infos.get_date") }}
          </span>
          {{ formatDate(reward.reward_created_at) }}
        </p>
        <p v-if="reward.date_giftty_liquidated_at != null">
          <span class="text-weight-bold">
            {{ $t("my_points.awards.infos.free_date") }} </span
          >{{ formatDate(reward.date_giftty_liquidated_at) }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { toMoneyLabel } from "@/shared/helpers/numbersManipulation";
import AwardMoreInfo from "./award-more-info.vue";
import moment from "moment";
import { ref } from "vue";

export default {
  name: "AwardGift",

  props: {
    reward: {
      type: Object,
      required: true,
    },
  },

  components: { AwardMoreInfo },

  setup() {
    const showMore = ref(false),
      getGiftImage = (json) => {
        if (json != null) {
          return JSON.parse(json).g;
        }
        return "/awards-icons/img_not_found.png";
      },
      formatDescription = (txt) => txt.substring(0, 200),
      formatDate = (date) => moment(date).format("DD/MM/YYYY  HH:mm:ss");

    return {
      getGiftImage,
      formatDescription,
      formatDate,
      toMoneyLabel,
      showMore,
      ref,
    };
  },
};
</script>

<style scoped lang="scss">
.body-content {
  background-color: white;
  padding: 20px;
}
p {
  font-size: 12pt;
}
.image-align {
  text-align: center;
}
.show-more {
  color: blue;
  font-weight: bolder;
}
.text-data {
  text-align: right;
  p {
    font-size: 9pt;
  }
}
@media only screen and (max-width: 1090px) {
  .image-align {
    text-align: center;
  }
  .text-data {
    text-align: left;
    padding-top: 20px;
  }
}
</style>
