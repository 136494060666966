<template>
  <page-title
    class="q-mb-lg"
    title-class
    :skeleton="false"
    :title="$t('my_points.awards.title')"
    :tooltip="$t('my_points.awards.help')"
  />
  <q-select
    outlined
    v-model="selected"
    :options="reward_statuses"
    option-label="label"
    option-value="value"
    class="select-page "
    :label="$t('my_points.filter')"
  />
  <div class="column q-mt-lg" v-if="!in_request">
    <no-award-closed v-if="all_rewards.length == 0" />
    <all-awards-items
      v-else
      v-for="reward in all_rewards"
      :key="reward.id"
      :reward="reward"
    />

    <award-pagination
      v-if="all_rewards.length != 0"
      :total_page="total_page"
      :selected="selected"
    />
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex-composition-helpers";
import AwardPagination from "./components/award-pagination.vue";
import allAwardsItems from "./components/all-awards-items";
import NoAwardClosed from "./components/no-award-closed";
import PageTitle from "../../components/page-title";
import { ref, watch, onMounted } from "vue";

const { useActions, useGetters } = createNamespacedHelpers("rescue_award");

export default {
  name: "Closed",

  components: {
    allAwardsItems,
    NoAwardClosed,
    PageTitle,
    AwardPagination,
  },

  setup() {
    const selected = ref(""),
      in_request = ref(true),
      { all_rewards, total_page, reward_statuses } = useGetters({
        all_rewards: "getAllRewards",
        total_page: "getRewardsTotalPages",
        reward_statuses: "getRewardsStatuses",
      }),
      {
        fetchAllRewards,
        fetchRewardsStatuses,
        fetchFilterRewards,
      } = useActions([
        "fetchAllRewards",
        "fetchRewardsStatuses",
        "fetchFilterRewards",
      ]);

    onMounted(() => {
      fetchAllRewards().finally((in_request.value = false));
      fetchRewardsStatuses();
    });

    watch(selected, (currentValue) => {
      if (currentValue.value != "") {
        fetchFilterRewards(currentValue.value).finally(
          (in_request.value = false)
        );
      } else fetchAllRewards().finally((in_request.value = false));
    });

    return {
      selected,
      in_request,
      total_page,
      all_rewards,
      reward_statuses,
    };
  },
};
</script>

<style scoped>
.select-page {
  background-color: white;
  width: 15% !important;
  border: none;
}

@media only screen and (max-width: 1090px) {
  .select-page {
    width: 100% !important;
  }
}

@media only screen and (max-width: 720px) {
  .select-page {
    width: 100% !important;
  }
}
</style>
