<template>
  <div class="body-content">
    <div class="row">
      <div class="col-12 col-md-2 image-align q-mb-md">
        <q-img width="10em" src="/awards-icons/award_voucher.png" />
      </div>
      <div class="col-12 col-md-6">
        <div class="q-mb-sm text-uppercase text-grey-8">
          {{
            $t("my_points.awards.voucher_award", {
              parent: reward.company_description,
            })
          }}
        </div>

        <ul>
          <li
            class="text-left"
            style="margin-left: -20px"
            v-for="(detail, i) in JSON.parse(reward.details)"
            :key="i"
          >
            <p v-html="detail" style="font-size: 9pt;" />
          </li>
        </ul>
      </div>
      <div class="col-12 col-md-4 text-data">
        <p v-if="reward.voucher_code != null">
          <span class="text-weight-bold">
            {{ $t("my_points.awards.show_voucher_code") }} </span
          >{{ changeCodeValue(reward.voucher_code) }}
          <a href="#" target="_blank" rel="noopener noreferrer">
            <q-icon name="open_in_new">
              <q-tooltip
                class="bg-dark"
                anchor="center left"
                :offset="[10, 10]"
              >
                Você será redirecionado
              </q-tooltip></q-icon
            >
          </a>
        </p>
        <p>
          <span class="text-weight-bold">
            {{ $t("my_points.awards.infos.get_date") }}
          </span>
          {{ formatDate(reward.reward_created_at) }}
        </p>
        <p v-if="reward.date_voucher_liquidated_at != null">
          <span class="text-weight-bold">
            {{ $t("my_points.awards.infos.free_date") }}
          </span>
          {{ formatDate(reward.date_voucher_liquidated_at) }}
        </p>
        <p v-if="reward.partner_email != null">
          <span class="text-weight-bold">
            {{ $t("my_points.awards.infos.send_email") }} </span
          >{{ reward.partner_email }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { toMoneyLabel } from "@/shared/helpers/numbersManipulation";
import moment from "moment";
import { ref } from "vue";

export default {
  name: "AwardVoucher",

  props: {
    reward: {
      type: Object,
      required: true,
    },
  },

  setup() {
    const showMore = ref(false);

    const formatDescription = (txt) => txt.substring(0, 200),
      formatDate = (date) => moment(date).format("DD/MM/YYYY  HH:mm:ss"),
      changeCodeValue = (txt) => `${txt.substring(0, 2)}*********`;

    return {
      formatDescription,
      formatDate,
      changeCodeValue,
      toMoneyLabel,
      showMore,
      ref,
    };
  },
};
</script>

<style scoped lang="scss">
.body-content {
  background-color: white;
  padding: 20px;
}
p {
  font-size: 12pt;
}
.image-align {
  text-align: center;
}
.show-more {
  color: blue;
  font-weight: bolder;
}
.text-data {
  text-align: right;
  p {
    font-size: 9pt;
  }
}
@media only screen and (max-width: 1090px) {
  .image-align {
    text-align: center;
  }
  .text-data {
    text-align: left;
  }
}
</style>
