<template>
  <div class="q-pa-lg flex flex-center">
    <q-pagination color="black" v-model="current" :max="total_page.last_page" />
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex-composition-helpers";
import { ref, watch } from "vue";

const { useActions } = createNamespacedHelpers("rescue_award");

export default {
  name: "AwardPagination",

  props: {
    total_page: {
      type: Object,
      required: true,
    },
    selected: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const current = ref(1);

    const { fetchFilterRewardsPaginate } = useActions([
      "fetchFilterRewardsPaginate",
    ]);

    watch(current, (currentValue) => {
      fetchFilterRewardsPaginate([
        props.selected.value,
        currentValue,
      ]).finally();
    });

    return {
      current,
    };
  },
};
</script>
